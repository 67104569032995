import FacebookIcon from '@/components/icons/facebook-icon'
import LinkedinIcon from '@/components/icons/linkedin-icon'
import PinterestIcon from '@/components/icons/pinterest-icon'
import TwitterIcon from '@/components/icons/twitter-icon'
import YoutubeIcon from '@/components/icons/youtube-icon'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { useMediaStore } from '@/stores/media-store'
import { useMemo } from 'react'
import { uid } from 'uid'
import { FooterProps } from './types/footer'

function Footer(props: Readonly<FooterProps>) {
  const { isMobile, isTablet } = useMediaStore()

  const {
    columns,
    socialMediaLinks,
    utilityLinks,
    copyrightText,
    addressLine1,
    addressLine2,
    footerColumn1Header,
    footerColumn2Header,
    footerColumn3Header,
    footerColumn4Header,
    desktopLogo,
    mobileLogo,
    homePageUrl,
    newsletter,
  } = props.model

  const headers = [footerColumn1Header, footerColumn2Header, footerColumn3Header, footerColumn4Header]

  const copy = useMemo<string>(
    () => `© ${new Date().getFullYear()} ${copyrightText}`,
    [copyrightText],
  )

  return (
    <footer
      className={`mt-[72px] lg:mt-14 bg-grey-secondary-light text-grey-dark pt-20 pb-8 ${
        props.model ? '' : 'hidden'
      }`}
      id="cpiSiteFooter"
    >
      <div className="container">
        <div className="space-y-8">
          <div className="md:flex">
            <div className="w-full flex flex-col space-y-4 md:w-1/3 md:space-y-6 col-span-4">
              {desktopLogo && (
                <SharedLink href={homePageUrl}>
                  <SharedImage
                    {...desktopLogo}
                    mobileUrl={mobileLogo?.url}
                    className="w-full max-w-[100px]"
                  />
                </SharedLink>
              )}
              <div className="flex flex-col">
                {addressLine1 && (
                  <p className="text-grey-dark font-normal md:text-base">
                    {addressLine1}
                  </p>
                )}
                {addressLine2 && (
                  <p className="text-grey-dark font-normal md:text-base">
                    {addressLine2}
                  </p>
                )}
              </div>
            </div>

            {columns?.length > 0 && (
              <div className="flex flex-wrap md:flex-nowrap w-full md:gap-x-8 gap-x-0">
                {columns.map((column, index) => (
                  <div
                    key={uid()}
                    className="w-1/2 mt-8 md:mt-0 md:w-1/4 space-y-3"
                  >
                    <h3 className="font-bold text-base text-grey-dark">{headers[index]}</h3>
                    <div className="border-grey-200 border-y w-3/4"></div>
                    {column.links.map((link) => (
                      <SharedLink
                        key={uid()}
                        {...link}
                        className="flex flex-col w-fit font-medium text-base text-dark-blue-primary hover:underline shared-transition"
                      >
                        <span className="block pr-2 md:pr-0">{link.text}</span>
                      </SharedLink>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          {newsletter && (
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between border-y border-grey-200 py-6">
              <div>
                {newsletter.heading && (
                  <p className="font-bold text-base text-grey-dark">
                    {newsletter.heading}
                  </p>
                )}

                {newsletter.subheading && (
                  <p className="font-normal text-base text-grey-dark">
                    {newsletter?.subheading}
                  </p>
                )}
              </div>
              <div className="relative md:w-full md:max-w-[330px]">
                {newsletter.subscribeButton && (
                  <SharedLink
                    className="md:absolute md:right-1 md:top-1/2 md:transform md:-translate-y-1/2"
                    style="primary"
                    {...newsletter.subscribeButton}
                  >
                    {newsletter.subscribeButton.text}
                  </SharedLink>
                )}
              </div>
            </div>
          )}
          <div className="text-xs flex flex-col space-y-6 md:space-y-0 md:justify-between md:flex-row md:items-center">
            <div
              className={`flex flex-col space-y-6 md:space-x-6 md:space-y-0 md:flex-row md:items-center ${
                copyrightText || utilityLinks?.length > 0 ? '' : 'hidden'
              }`}
            >
              <div>{copy}</div>
              <div>
                {utilityLinks?.map((utilLink, index) => (
                  <SharedLink
                    key={uid()}
                    {...utilLink}
                    className="font-mulish font-normal text-grey-dark"
                  >
                    <span>{utilLink.text}</span>
                    {index !== utilityLinks?.length - 1 && (
                      <span className="mx-2.5">|</span>
                    )}
                  </SharedLink>
                ))}
              </div>
            </div>
            <div
              className={`flex space-x-6 ${
                socialMediaLinks?.length > 0 ? '' : 'hidden'
              }
              ${isMobile ? 'h-[120px]' : isTablet ? 'h-[120px]' : ''}`}
            >
              {socialMediaLinks?.map((socialLink) => (
                <SharedLink key={uid()} {...socialLink} className="w-fit">
                  {socialLink.type === 'fb' && <FacebookIcon />}
                  {socialLink.type === 'x' && <TwitterIcon />}
                  {socialLink.type === 'lin' && <LinkedinIcon />}
                  {socialLink.type === 'yt' && <YoutubeIcon />}
                  {socialLink.type === 'pin' && <PinterestIcon />}
                </SharedLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
