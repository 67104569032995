import { epiContentApi } from '@/api/content-delivery'
import { ContentModel, ValidationIssueModel } from '@/api/types/content-delivery-types'
import { ValidationIssue } from '@/api/validation'
import { viewCartGTM } from '@/components/shared/analytics/gtm-manager'
import SharedButton from '@/components/shared/buttons/shared-button'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'
import { useCustomerHardLockStore } from '@/stores/customer-hard-lock-store'
import { useGlobalSettings } from '@/stores/global-settings'
import { useCartStore } from '@/stores/useCartStore'
import { useUserStore } from '@/stores/useUserStore'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SharedLink from '../../shared/buttons/shared-link'
import OrderSummary from '../../shared/order-summary/order-summary'
import CartLineItem from './components/cart-line-item'
import { CartPageProps } from './types/cart-page-types'
import withHydration from '@/lib/withHydration'
import { useShippingSameAsBilling } from '@/stores/useShippingSameAsBilling'

const CartPage = (props: Readonly<CartPageProps>) => {
  const { t } = useTranslation()
  const { user, handleSignIn } = useUserStore()
  const {
    cartData,
    validatedCartData,
    removeCartItem,
    calculateCartLine,
    validateCart,
    updateCart,
    isLoadingCartTotals,
    setIsLoadingCartTotals,
    updateCartLineQuantity,
  } = useCartStore()
  const {setShippingSameAsBilling} = useShippingSameAsBilling()
  const hasCartItems = (user?.cart?.shipments?.flatMap((x) => x.lineItems)?.length ?? 0) > 0

  const [triggerViewCartGMT, setTriggerViewCartGMT] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[] | null>(null)

  const globalSettings = useGlobalSettings((s) => s.globalSettings)
  const pdpUrl = globalSettings?.productDetailsPageUrl
  const navigate = useNavigate()
  const {
    pageTitle,
    pageDescription,
    continueShoppingProps,
    emptyContentArea,
    topContentArea,
    orderSummaryContentArea,
    bottomContentArea,
    localizations,
    eventRegistrationAttendanceModels,
  } = props.model

  const { isCustomerHardLocked, openModal } = useCustomerHardLockStore()

  const {
    estimatedOrderTotal,
    taxAndShippingNotice,
    productSubtotal,
    productTaxes,
    productTotal,
    shippingCost,
    shippingFree,
    cartSummaryTitle,
    continueShopping,
    proceedToCheckout,
    productRemovedTitle,
    productRemovedDesc,
    productUnavailable,
  } = localizations

  const orderSummaryLocalizations = {
    estimatedOrderTotal,
    taxAndShippingNotice,
    productSubtotal,
    productTaxes,
    productTotal,
    shippingCost,
    shippingFree,
    cartSummaryTitle,
  }

  useEffect(() => {
    const load = async () => {
      setIsLoadingCartTotals(true)
      await validateCart()

      if (!hasCartItems)
      {
        setShippingSameAsBilling(false)
      }
      setIsLoadingCartTotals(false)
    }
    load()
  }, [])


  const navigateToCheckout = () => {
    if (!hasCartItems) return
    if (globalSettings?.checkoutPageUrl) {
      navigate(globalSettings.checkoutPageUrl)
      toast.dismiss()
    }
  }

  const cartValidationTrigger = async () => {
    if (validatedCartData?.validationIssues && validatedCartData?.validationIssues.length > 0) {
      for (const issue of validatedCartData.validationIssues) {
        await processValidationErrors(issue)
      }
    }
  }

  const processValidationErrors = async (issue: ValidationIssueModel) => {
    if (issue.validationIssues.length > 0) {
      const content = await epiContentApi<ContentModel>(issue.contentId)
      const productName = content.name ?? t('Cart.ProductNameNotSet')
      
      for (const error of issue.validationIssues) {
        if (
          error.includes(ValidationIssue.RemovedDueToInvalidMaxQuantitySetting) ||
          error.includes(ValidationIssue.RemovedDueToUnavailableItem) ||
          error?.includes(ValidationIssue.RemovedDueToInsufficientQuantityInInventory)
        ) {
          setErrors((prevErrors) => [
            ...(prevErrors || []),
            `${productRemovedTitle}||${productRemovedDesc} ${productName}`,
          ])
        }

        if (error.includes(ValidationIssue.AdjustedQuantityByAvailableQuantity)) {
          setErrors((prevErrors) => [
            ...(prevErrors || []),
            `${t('Cart.Errors.AdjustedQuantityByAvailableQuantity.Title')}||${t('Cart.Errors.AdjustedQuantityByAvailableQuantity.Description', { 0: productName })}`,
          ])
        }
        if (error.includes(ValidationIssue.AdjustedQuantityByMaxQuantity)) {
          setErrors((prevErrors) => [
            ...(prevErrors || []),
            `${t('Cart.Errors.AdjustedQuantityByMaxQuantity.Title')}||${t('Cart.Errors.AdjustedQuantityByMaxQuantity.Description', { 0: productName })}`,
          ])
        }
        if (error.includes(ValidationIssue.AdjustedQuantityByMinQuantity)) {
          setErrors((prevErrors) => [
            ...(prevErrors || []),
            `${t('Cart.Errors.AdjustedQuantityByMinQuantity.Title')}||${t('Cart.Errors.AdjustedQuantityByMinQuantity.Description', { 0: productName })}`,
          ])
        }
      }
    }
  }

  const handleCheckoutClick = async () => {
    if (!user) {
      await handleSignIn()
      return
    }

    if (isCustomerHardLocked) {
      openModal()
      return
    }

    if (!hasCartItems) {
      setErrors((prevErrors) => [
        ...(prevErrors || []),
        t('Cart.Errors.NoCartItems')
      ])
    }
    const isValid = await validateCart()
    
    if (!isValid) {
      await cartValidationTrigger()
      await updateCart(validatedCartData?.cart!, false)
      setIsLoadingCartTotals(false)
      return
    }
    navigateToCheckout()
  }

  const customerHardLockCheck = async () => {
    if (isCustomerHardLocked) {
      openModal()
    } else {
      await handleCheckoutClick()
    }
  }

  useEffect(() => {
    if (errors) {
      toast(
        <div className="flex flex-col gap-2">
          {errors.map((error) => {
            const [title, description] = error.split('||')
            return (
              <>
                <span className="text-red-600">{title}</span>
                <span className="text-black">{description}</span>
              </>
            )
          })}
          {hasCartItems && (
            <SharedButton className="flex w-full flex-row gap-2" style="primary" onClick={navigateToCheckout}>
              {localizations.proceedToCheckout}
            </SharedButton>
          )}
        </div>,
        { position: 'top-right' },
      )
      setErrors(null)
    }
  }, [errors])

  const eventRegistrationAttendanceMap = new Map()

  if (eventRegistrationAttendanceModels) {
    Object.entries(eventRegistrationAttendanceModels).map((eventRegistrationAttendanceModel) => {
      eventRegistrationAttendanceMap.set(
        eventRegistrationAttendanceModel[0].toLowerCase(),
        eventRegistrationAttendanceModel[1],
      )
    })
  }

  const getEventRegistrationAttendance = (code: string) => {
    if (!code) return null

    return eventRegistrationAttendanceMap.get(code.toLowerCase()) || null
  }

  useEffect(() => {
    if (!cartData || validatedCartData?.totals.total === undefined) return

    if (!triggerViewCartGMT) {
      viewCartGTM(cartData)
      setTriggerViewCartGMT(true)
    }
  }, [cartData, validatedCartData?.totals.total])

  return (
    <div className="container flex flex-col pt-0 font-mulish">
      <div className="flex grid-cols-8 flex-col gap-7 lg:grid lg:gap-[36px] rounded-sm">
        <div className="mt-8 flex-1 pb-[56px] lg:col-span-5">
          <div className="flex w-full flex-col gap-[12px] lg:pb-8">
            <h1 className="text-3xl font-semibold text-grey-dark">{pageTitle}</h1>
            <span>{pageDescription}</span>
          </div>

          {topContentArea && topContentArea.length > 0 && (
            <div className="reduced-font mb-5">{RenderContentArea(topContentArea)}</div>
          )}

          {continueShoppingProps && (
            <div className="flex justify-end pb-[10px]">
              <SharedLink {...continueShoppingProps}>
                <span>{continueShopping}</span>
              </SharedLink>
            </div>
          )}

          <div className="flex flex-col gap-2">
            {cartData?.cartLines && cartData.cartLines.length > 0
              ? cartData?.cartLines.map((product, idx) => (
                  <CartLineItem
                    localizations={{
                      productUnavailable: productUnavailable,
                    }}
                    key={product.id}
                    cartLineModel={product}
                    cartInfo={cartData.cartInfo}
                    updateCartLineQuantity={updateCartLineQuantity}
                    validationIssues={
                      validatedCartData?.validationIssues?.find((x) => x.contentId === product.contentId)
                        ?.validationIssues
                    }
                    pdpUrl={pdpUrl}
                    calculateCartLine={calculateCartLine}
                    removeCartItem={removeCartItem}
                    eventRegistrationAttendance={getEventRegistrationAttendance(product.code)}
                  />
                ))
              : RenderContentArea(emptyContentArea)}
          </div>
          {bottomContentArea && bottomContentArea.length > 0 && (
            <div className="reduced-font my-3">{RenderContentArea(bottomContentArea)}</div>
          )}
        </div>
        <div className="rounded-b-md -mx-[1.125rem] h-auto flex-auto bg-grey-secondary-light px-[1.125rem] pt-0 text-grey-dark lg:col-span-3 lg:mx-0 lg:px-[36px] lg:pt-8">
          <OrderSummary
            onPage="cart"
            isLoading={isLoadingCartTotals}
            cartInfo={{
              market: user?.authInfo?.market ?? DEFAULT_MARKET,
              currency: user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE,
            }}
            cartTotals={validatedCartData?.totals}
            localizations={orderSummaryLocalizations}
          />
          <span className="text-xs">{taxAndShippingNotice}</span>
          <SharedButton
            onClick={customerHardLockCheck}
            className="mt-5 flex w-full flex-row items-center"
            style="primary"
          >
            <LockClosedIcon className="w-5" />
            <span className="pl-3">{proceedToCheckout}</span>
          </SharedButton>
          <div className="reduced-font my-3">{RenderContentArea(orderSummaryContentArea)}</div>
        </div>
      </div>
    </div>
  )
}

export default withHydration(CartPage)
