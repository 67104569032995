import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedButton from '@/components/shared/buttons/shared-button'
import IndexCard from '@/components/shared/index/components/card'
import IndexCategories from '@/components/shared/index/components/categories'
import FeaturedCard from '@/components/shared/index/components/featured'
import HeroIndex from '@/components/shared/index/components/hero'
import Highlights from '@/components/shared/index/components/highlights'
import LegislationCard from '@/components/shared/index/components/legislation-card'
import StateCategories from '@/components/shared/index/components/state-categories'
import IndexTab from '@/components/shared/index/components/tabs'
import LoadingSpinner from '@/components/shared/loading-spinner'
import { useIndexPageStore } from '@/stores/index-store'
import { useMediaStore } from '@/stores/media-store'
import React, { useEffect } from 'react'
import { uid } from 'uid'
import { IndexProps } from './types/index-types'

const IndexPage = (props: Readonly<IndexProps>) => {
  const { model } = props

  const {
    cards,
    isLoading,
    moreItems,
    page,
    setPage,
    setPageUrl,
    setPageSize,
    fetchResults,
    resetState,
  } = useIndexPageStore()

  const { isTablet, isMobile } = useMediaStore()

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchResults()
  }

  useEffect(() => {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      setPageSize(6)
    } else {
      setPageSize(9)
    }
  }, [isTablet])

  useEffect(() => {
    setPageUrl(model.pageUrl)
    resetState()
    fetchResults()
  }, [])

  return (
    <>
      <Breadcrumbs />
      <div className="container flex flex-col space-y-14 md:space-y-20">
        {model.hero && (
          <HeroIndex
            heading={model.hero.heading}
            subheading={model.hero.subheading}
          />
        )}

        {model.featured && <FeaturedCard {...model.featured} />}

        {model.highlights?.list && model.highlights.list.length > 0 && (
          <Highlights {...model.highlights} />
        )}

        <div className="flex flex-col space-y-6 md:space-y-12">
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-4 md:justify-between">
            {model.tabs && model.tabs.length > 0 && (
              <>
                {!isMobile ? (
                  <ul className="flex flex-wrap items-center space-x-1">
                    {model.tabs.map((tab, index) => (
                      <li key={uid()}>
                        <IndexTab {...tab} index={index} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex">
                    <ul className="snap-x overflow-x-auto flex space-x-0.5 mr-[-18px] pb-2 -mb-2">
                      {model.tabs.map((tab, index) => (
                        <li key={uid()} className="snap-start">
                          <IndexTab {...tab} index={index} />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
            {model.categories?.list && model.categories.list.length > 0 && (
              <>
                {model.contentType?.includes('LegislationIndexPage') ? (
                  <StateCategories {...model.categories} />
                ) : (
                  <IndexCategories {...model.categories} />
                )}
              </>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 min-h-[365px] w-full">
            {cards.length > 0 && !isLoading && (
              <>
                {cards.map((card) => (
                  <React.Fragment key={uid()}>
                    {model.contentType?.includes('LegislationIndexPage') ? (
                      <LegislationCard {...card} />
                    ) : (
                      <IndexCard {...card} />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

            {cards.length < 1 && isLoading && (
              <div className="col-span-1 sm:col-span-2 lg:col-span-3 flex h-full w-full items-center justify-center">
                <LoadingSpinner className="h-5 w-5 text-grey-light" />
              </div>
            )}

            {cards.length < 1 && !isLoading && (
              <div className="col-span-1 sm:col-span-2 lg:col-span-3 flex h-full w-full items-center">
                <div className="flex justify-center py-8 px-4 bg-grey-100 w-full rounded-lg">
                  <span className="text-black text-2xl sm:text-3xl font-bold">
                    {model.noResultsMessage}
                  </span>
                </div>
              </div>
            )}
          </div>
          {cards.length > 0 && moreItems && (
            <div className="flex justify-center">
              <SharedButton
                ariaLabel={model.loadMoreAriaText}
                style="primary"
                size="regular"
                className="space-x-3"
                onClick={handleLoadMore}
              >
                {isLoading && (
                  <span>
                    <LoadingSpinner className="h-5 w-5 text-white" />
                  </span>
                )}
                <span>{model.loadMoreButtonText}</span>
              </SharedButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default IndexPage
