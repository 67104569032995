import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedButton from '@/components/shared/buttons/shared-button'
import SharedLink from '@/components/shared/buttons/shared-link'
import HeroIndex from '@/components/shared/index/components/hero'
import IndexTab from '@/components/shared/index/components/tabs'
import LoadingSpinner from '@/components/shared/loading-spinner'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useIndexPageStore } from '@/stores/index-store'
import { useMediaStore } from '@/stores/media-store'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { uid } from 'uid'
import CaseStudyCard from './components/case-study-card'
import { CaseStudyIndexProps } from './types/index-types'

const CaseStudyIndexPage = (props: Readonly<CaseStudyIndexProps>) => {
  const { model } = props

  const {
    cards,
    isLoading,
    moreItems,
    page,
    setPage,
    setPageUrl,
    setPageSize,
    fetchResults,
    resetState,
  } = useIndexPageStore()

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchResults()
  }

  const { isMobile } = useMediaStore()

  useEffect(() => {
    setPageSize(6)
    setPageUrl(model.pageUrl)
    resetState()
    fetchResults()
  }, [])

  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 md:space-y-20">
        {model.hero && (
          <div className="container">
            <HeroIndex
              heading={model.hero.heading}
              subheading={model.hero.subheading}
            >
              {model.hero.primaryCtaButton && (
                <div className="flex items-center justify-center">
                  <SharedLink
                    {...model.hero.primaryCtaButton}
                    style={model.hero.primaryCtaButton.buttonStyle}
                    size={model.hero.primaryCtaButton.buttonSize}
                    className=" w-full md:w-auto space-x-3 shadow-xl"
                  >
                    <span>{model.hero.primaryCtaButton.text}</span>
                    <span>
                      <ArrowRightIcon className="h-5 w-5" />
                    </span>
                  </SharedLink>
                </div>
              )}
            </HeroIndex>
          </div>
        )}

        <div className="container flex flex-col space-y-6 md:space-y-12">
          {model.tabs && model.tabs.length > 0 && (
            <>
              {!isMobile ? (
                <div className="flex flex-col justify-center items-center space-y-1">
                  <ul className="flex space-x-1">
                    {model.tabs.map((tab, index) => (
                      <>
                        {index < 4 && (
                          <li key={uid()}>
                            <IndexTab
                              {...tab}
                              index={index}
                              hideMinWidth={true}
                            />
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                  {model.tabs.length > 4 && (
                    <ul className="flex space-x-1">
                      {model.tabs.map((tab, index) => (
                        <>
                          {index > 3 && index < 9 && (
                            <li key={uid()}>
                              <IndexTab
                                {...tab}
                                index={index}
                                hideMinWidth={true}
                              />
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <div className="flex">
                  <ul className="snap-x overflow-x-auto flex space-x-0.5 mr-[-18px] pb-2 -mb-2">
                    {model.tabs.map((tab, index) => (
                      <li key={uid()} className="snap-start">
                        <IndexTab {...tab} index={index} hideMinWidth={true} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 min-h-[369px] w-full">
            {cards.length > 0 && !isLoading && (
              <>
                {cards.map((card) => (
                  <React.Fragment key={uid()}>
                    <CaseStudyCard
                      {...card}
                      isFeatured={card.isFeatured}
                      setFeaturedAspectRatio={cards.length < 3}
                    />
                  </React.Fragment>
                ))}
              </>
            )}

            {cards.length < 1 && isLoading && (
              <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center justify-center">
                <LoadingSpinner className="h-5 w-5 text-grey-light" />
              </div>
            )}

            {cards.length < 1 && !model.featured && !isLoading && (
              <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center">
                <div className="flex justify-center py-8 px-4 bg-grey-100 w-full rounded-lg">
                  <span className="text-black text-2xl sm:text-3xl font-bold">
                    {model.noResultsMessage}
                  </span>
                </div>
              </div>
            )}
          </div>
          {cards.length > 0 && moreItems && (
            <div className="flex justify-center">
              <SharedButton
                ariaLabel={model.loadMoreAriaText}
                style="primary"
                size="regular"
                className="space-x-3"
                onClick={handleLoadMore}
              >
                {isLoading && (
                  <span>
                    <LoadingSpinner className="h-5 w-5 text-white" />
                  </span>
                )}
                <span>{model.loadMoreButtonText}</span>
              </SharedButton>
            </div>
          )}
        </div>

        {model.pageContent && (
          <>
            {model.pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                key={`pageContent-${i}`}
              />
            ))}
          </>
        )}
      </div>
    </>
  )
}
export default CaseStudyIndexPage
