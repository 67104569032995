import MediaContent from '@/components/blocks/featured-media-block/components/media-content'
import Modal from '@/components/shared/modal/shared-modal'
import { useLocationsStore } from '@/stores/locations-store'
// @ts-ignore
import { DateTime } from 'luxon'
import { useState } from 'react'
import { VideoDetailHeroProps } from '../types/video-detail-types'

const VideoDetailHero = (props: VideoDetailHeroProps) => {
  const { heading, videoPlaceholder, video, publishDate, industry } = props
  const [showModal, setShowModal] = useState(false)

  const { selectedCountry } = useLocationsStore()

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date)
        .setLocale(country)
        .toLocaleString(DateTime.DATE_FULL)
    } else {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
    }
  }

  return (
    <div className="flex flex-col space-y-4 lg:space-y-6">
      {videoPlaceholder && (
        <div className="flex-1 relative flex mx-[-18px] md:mx-0 aspect-video">
          <MediaContent
            type="Video"
            video={video}
            image={videoPlaceholder}
            preloadImage={true}
            removeMobileImageRadius={true}
            setShowModal={setShowModal}
          />
        </div>
      )}
      <div className="flex flex-col space-y-2 md:space-y-3">
        {industry && (
          <div className="text-grey-light uppercase text-xs font-bold tracking-[1.2px]">
            {industry}
          </div>
        )}
        {heading && (
          <div
            className="text-stone-800 text-2xl lg:text-4xl font-semibold"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {publishDate && (
          <div className="text-xs text-grey-light">
            {formatDate(publishDate)}
          </div>
        )}
      </div>

      <Modal
        transparentBg={true}
        closeButtonLabel="close"
        showModal={showModal}
        onClose={() => setShowModal(false)}
      >
        <iframe
          src={`https://www.youtube.com/embed/${video}`}
          title="YouTube video player"
          className="w-full lg:w-[832px] aspect-video lg:m-32"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Modal>
    </div>
  )
}

export default VideoDetailHero
