import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { WorkplaceTrainingLandingPageProps } from './types/workplace-training-landing-page-types'

// this is the wvp calc survey/container page
const handleScript = (customScript: string) => {
  const script = document.createElement('script')
  script.type = 'application/javascript'
  script.text = customScript
  document.body.appendChild(script)
}

const WorkplaceTrainingLandingPage: React.FC<
  WorkplaceTrainingLandingPageProps
> = ({ model }) => {
  const [pageIsLoaded, setPageIsLoaded] = useState(false)

  useEffect(() => {
    setPageIsLoaded(true)
  }, [])

  useEffect(() => {
    if (pageIsLoaded) {
      if (model?.formScripts) {
        handleScript(model.formScripts)
      }
      if (model?.additionalFormScripts) {
        handleScript(model.additionalFormScripts)
      }
    }
  }, [pageIsLoaded])

  return (
    <div className="flex flex-col bg-[#efeff4] min-h-screen">
        {model.hero && <BlockComponentSelector {...model.hero} />}
        <div id="dKbnT5pkMJMLq1hxxoif6cufmCLyS2GWTamIHt-4uAKQ"></div>
        <div
          className="d365-mkt-config"
          style={{ display: 'none' }}
          data-website-id="KbnT5pkMJMLq1hxxoif6cufmCLyS2GWTamIHt-4uAKQ"
          data-hostname="151ccc7491c74cdcbe3811dd5a6525fe.svc.dynamics.com"
        ></div>
        {(model.htmlScripts ||
          model.formScripts ||
          model.additionalFormScripts) && (
          <div className="container">
            {model.htmlScripts && (
              <div dangerouslySetInnerHTML={{ __html: model.htmlScripts }} />
            )}
          </div>
        )}

        <div className="flex flex-col space-y-[72px] md:space-y-32">
          {model.pageContent?.map((item, i) => (
            <BlockComponentSelector {...item} key={`pageContent-${i}`} />
          ))}
        </div>
    </div>
  )
}
export default WorkplaceTrainingLandingPage
