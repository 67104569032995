import { SectionHeaderProp } from '@/types/shared'

const SectionHeader = (props: Readonly<SectionHeaderProp>) => {
  return (
    <div className="flex space-y-4 flex-col w-full mb-8 lg:mb-14 md:flex-row md:justify-between md:items-center">
      {(props.heading || props.smallIntro) && (
        <div
          className={`${
            props.textCenter ? 'w-full justify-center' : 'md:w-5/12'
          } flex`}
        >
          <div
            className={`flex flex-col space-y-4 md:space-y-8 ${
              props.textCenter ? 'text-center lg:w-1/2' : 'w-full'
            }`}
          >
            <div className="flex flex-col md:space-y-1">
              {props.smallIntro && (
                <p className="font-bold text-xs font-mulish text-dark-blue-primary uppercase tracking-[.075rem]">
                  {props.smallIntro}
                </p>
              )}
              {props.heading && (
                <h2 className="font-mulish text-grey-dark font-bold text-2xl md:text-4xl">
                  {props.heading}
                </h2>
              )}
            </div>
            {props.subheading && (
              <p className="font-normal font-mulish text-base md:text-lg">
                {props.subheading}
              </p>
            )}
          </div>
        </div>
      )}

      {props.children
        ? props.children // CTA buttons area
        : props.description && (
            <div className="w-full md:w-1/2">
              <p className="font-normal font-mulish text-base md:text-lg md:pt-5">
                {props.description}
              </p>
            </div>
          )}
    </div>
  )
}

export default SectionHeader
