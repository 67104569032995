import MediaContent from '@/components/blocks/featured-media-block/components/media-content'
import { VideoBlockProp } from '@/components/blocks/video-block/types/video-block-types'
import Modal from '@/components/shared/modal/shared-modal'
import SectionHeader from '@/components/shared/section-header'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const YTAutoplayParams =
  '?autoplay=1&modestbranding=1&rel=0&fs=1&enablejsapi=1'

export const YTNoAutoplayParams = '?modestbranding=1&rel=0&fs=1&enablejsapi=1'

const VideoBlock = (props: Readonly<VideoBlockProp>) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  const { heading, subheading, optionalDescription, placeholderImage, video } = props.model

  return (
    <section className={!props.hideContainerClass ? 'container' : ''}>
      {heading && subheading && (
        <SectionHeader
          heading={heading}
          subheading={subheading}
          smallHeadingGap={true}
        />
      )}

      <div className={'flex flex-col'}>
        <MediaContent
          type="Video"
          video={video}
          image={placeholderImage}
          setShowModal={setShowModal}
        />
      </div>
      {optionalDescription && (
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: optionalDescription }}
        ></div>
      )}

      <Modal
        transparentBg={true}
        closeButtonLabel="close"
        showModal={showModal}
        onClose={() => setShowModal(false)}
      >
        {showModal ? (
          <iframe
            src={`https://www.youtube.com/embed/${video + YTAutoplayParams}`}
            title={t('Blocks.VideoBlock.YouTubeFrameTitle')}
            className="w-full lg:w-[832px] aspect-video lg:m-32"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        ) : (
          <iframe
            src={`https://www.youtube.com/embed/${video + YTNoAutoplayParams}`}
            title={t('Blocks.VideoBlock.YouTubeFrameTitle')}
            className="w-full lg:w-[832px] aspect-video lg:m-32"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        )}
      </Modal>
    </section>
  )
}

export default VideoBlock
