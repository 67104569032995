import { useEffect, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import { CheckoutPageLocalizationModel, CheckoutStepOneBillingFormInputs, CheckoutStepOneShippingFormInputs } from "../../types/checkout-types"
import { AddressModel, ShippingAndBillingAddressModel } from "@/api/types/content-delivery-types"
import { useCartStore } from "@/stores/useCartStore"
import { AddressBookFormInputs, SelectOption } from "@/components/shared/address/types/address-types"
import { useAddressStore } from "@/stores/useAddressStore"
import AddressSelect from "@/components/shared/address/address-select"
import { CustomerAddressType } from "@/api/types/content-delivery-enums"
import StepOneBillingForm from "../step-one-billing-form"
import AddressCard from "../address-card"
import SharedButton from "@/components/shared/buttons/shared-button"
import StepOneShippingForm from "../step-one-shipping-form"
import ExtendedModal from "@/components/shared/modal/extended-modal"
import AddressBookForm from "@/components/shared/address/address-form"
import { PlusIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { validateAddress } from "@/api/validation"
import LoadingSpinner from "@/components/shared/loading-spinner"
import { useShippingSameAsBilling } from "@/stores/useShippingSameAsBilling"
import { useUserStore } from "@/stores/useUserStore"

interface StepOneProps {
    localizations: CheckoutPageLocalizationModel
    setStep: React.Dispatch<React.SetStateAction<number>>
    setStepOneFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}

const BillingShippingStep: React.FC<Readonly<StepOneProps>> = (props: Readonly<StepOneProps>) => {
    const { t } = useTranslation()
    const [addressSaving, setAddressSaving ] = useState<boolean>(false)
    const { user } = useUserStore()
    const { hasWarning, customer, addresses, addressFormEditMode, showAddressBookModal, setHasWarning, setShowAddressBookModal, getAddressBookData, createAddress, saveAddress, addressModelToShippingAndBilling, setAddressFieldsHidden } = useAddressStore()
    const { cartData, validateCart, updateCartShippingAddress, loadUserCart, updateCartShippingMethod } = useCartStore()
    const { disabled, shippingSameAsBilling, setDisabled, setShippingSameAsBilling } = useShippingSameAsBilling()
    const [ selectShippingAddressCreate, setSelectShippingAddressCreate] = useState<boolean>(false)
    const [ selectBillingAddressCreate, setSelectBillingAddressCreate] = useState<boolean>(false)
    // Billing default loads
    const cartBillingAddress = cartData?.fullCart && cartData?.fullCart.payments.length > 0 ? cartData.fullCart.payments[0].billingAddress : null
    const [ selectedBillingAddress, setSelectedBillingAddress ] = useState<ShippingAndBillingAddressModel | null>(cartBillingAddress)
    const [ billingSelectValue, setBillingSelectValue ] = useState<SelectOption | null>(
        cartBillingAddress ? {
            value: selectedBillingAddress?.name ?? "", 
            label: `${selectedBillingAddress?.name}`
        } : null
    )


    // Shipping default loads
    const cartShippingAddress = cartData?.fullCart && cartData?.fullCart?.shipments.length > 0 ? cartData?.fullCart.shipments[0].shippingAddress : null
    const [ selectedShippingAddress, setSelectedShippingAddress ] = useState<ShippingAndBillingAddressModel | null>(cartShippingAddress)
    const [ shippingSelectValue, setShippingSelectValue ] = useState<SelectOption | null>(
        cartShippingAddress ? {
            value: selectedShippingAddress?.name ?? "", 
            label: `${selectedShippingAddress?.name}`
        } : null
    )

    const [showBillingForm, setShowBillingForm] = useState<boolean>(false)
    const [showShippingForm, setShowShippingForm] = useState<boolean>(false)

    const {
        localizations,
        setStepOneFormSubmitting
    } = props;

    useEffect(() => {
        getAddressBookData()
    }, [])

    const setDefaultCartAddress = async (billingAddress: ShippingAndBillingAddressModel | null, shippingAddress: ShippingAndBillingAddressModel | null, isShippingSameAsBilling: boolean, taxesExemption?: boolean) => {
        if (billingAddress || shippingAddress) {
            await updateCartShippingAddress({ billingAddress: billingAddress, shippingAddress: shippingAddress, isShippingSameAsBilling: isShippingSameAsBilling, taxExemption: taxesExemption})
            return true
        }
        return false
    }

    const extractBillingAddressFormData = (data: CheckoutStepOneBillingFormInputs) => {
        const billingAddressPayload = data.address1 ? {
            firstName: user?.customerInfo?.firstName ?? "",
            lastName: user?.customerInfo?.lastName ?? "",
            line1: data.address1,
            line2: data.address2 ?? null,
            city: data.city,
            countryName: data.country ?? null,
            countryCode: data.countryCode ?? null,
            postalCode: data.postalCode ?? null,
            regionName: data.provinceOrState ?? null,
            email: data.email ?? null,
            phoneNumber: data.phone ?? null,
            name: data.addressName ?? null
        } : null
        return billingAddressPayload
    }

    const extractShippingAddressFormData = (data: CheckoutStepOneShippingFormInputs)=>{
        const shippingAddressPayload: ShippingAndBillingAddressModel | null = data.address1 ? {
            firstName: data.firstName,
            lastName: data.lastName,
            line1: data.address1,
            line2: data.address2 ?? "",
            city: data.city,
            countryName: data.country ??  "",
            countryCode: data.countryCode ?? null,
            postalCode: data.postalCode ??  "",
            regionName: data.provinceOrState ??  "",
            email: data.email ??  "",
            phoneNumber: "",
            name: data.addressName ?? null
        } : null
        return shippingAddressPayload
    }

    const onSaveBillingForm: SubmitHandler<CheckoutStepOneBillingFormInputs> = async (data, e) => {
        if (data) {
            setStepOneFormSubmitting(true)
            setShowBillingForm(false)
            if(data.countryCode !== selectedBillingAddress?.countryCode)
            {
                updateCartShippingMethod({shipmentId: cartData?.fullCart?.shipments[0]?.id ?? null, shippingMethod: null})
            }
            const billingAddressFormData = extractBillingAddressFormData(data)
            const isComplete = await setDefaultCartAddress(billingAddressFormData, null, shippingSameAsBilling, false)
            if (isComplete)
            {
                const countryName = localizations.countries.find(country => country.name === data.countryCode)?.code ?? "";
                let address: AddressModel = {
                    customerId: user?.customerInfo?.customerId,
                    extendedProperties: [],
                    countryCode: data.countryCode,
                    city: data.city,
                    firstName: customer?.firstName,
                    lastName: customer?.lastName,
                    countryName: countryName,
                    email: data.email,
                    daytimePhoneNumber: data.phone,
                    eveningPhoneNumber: data.phone,
                    line1: data.address1,
                    line2: data.address2,
                    name: data.addressName,
                    postalCode: data.postalCode,
                    region: data.provinceOrState,
                    regionCode: data.provinceOrState,
                }
                const addressPayload = createAddress(address, CustomerAddressType.Billing)
                await saveAddress(addressPayload, true)
                setSelectedBillingAddress(addressModelToShippingAndBilling(addressPayload))
                setBillingSelectValue({value: addressPayload.name, label: `${addressPayload.name}`})
                await loadUserCart()
                window.scrollTo({ top: 0, behavior: 'smooth' })
                setStepOneFormSubmitting(false)
            }
        }
    }

    const onSaveShippingForm: SubmitHandler<CheckoutStepOneShippingFormInputs> = async (data, e) => {
        if (data) {
            setStepOneFormSubmitting(true)
            setShowShippingForm(false)
            if(data.countryCode !== selectedShippingAddress?.countryCode)
            {
                updateCartShippingMethod({shipmentId: cartData?.fullCart?.shipments[0]?.id ?? null, shippingMethod: null })
            }
            const shippingAddressFormData = extractShippingAddressFormData(data)
            const isComplete = await setDefaultCartAddress(null, shippingAddressFormData, shippingSameAsBilling, false)
            if (isComplete)
            {
                const countryName = localizations.countries.find(country => country.name === data.countryCode)?.code ?? "";
                
                let address: AddressModel = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    customerId: user?.customerInfo?.customerId,
                    extendedProperties: [],
                    countryCode: data.countryCode,
                    city: data.city,
                    countryName: countryName,
                    email: data.email,
                    line1: data.address1,
                    line2: data.address2,
                    name: data.addressName,
                    postalCode: data.postalCode,
                    region: data.provinceOrState,
                    regionCode: data.provinceOrState,
                }
                const addressPayload = createAddress(address, CustomerAddressType.Shipping)
                await saveAddress(addressPayload, true)
                setShippingSameAsBilling(false)
                setSelectedShippingAddress(addressModelToShippingAndBilling(addressPayload))
                setShippingSelectValue({value: addressPayload.name, label: `${addressPayload.name}`})
                await loadUserCart()
                window.scrollTo({ top: 0, behavior: 'smooth' })
                setStepOneFormSubmitting(false)
            }
        }
    }

    const onSaveAddress = async (data: AddressBookFormInputs) => {
        if (!customer) return;
        setStepOneFormSubmitting(true)
        setAddressSaving(true)
       
        const countryName = localizations.countries.find(country => country.name === data.countryCode)?.code ?? "";
        const address: AddressModel = {
           customerId: user?.customerInfo?.customerId,
           extendedProperties: [],
           countryCode: data.countryCode,
           city: data.city,
           firstName: !data.isShipping ? customer.firstName : data.firstName,
           lastName: !data.isShipping ? customer.lastName : data.lastName,
           daytimePhoneNumber: data.phoneNumber,
           eveningPhoneNumber: data.phoneNumber,
           countryName: countryName,
           email: data.email,
           line1: data.address1,
           line2: data.address2,
           name: data.addressName,
           postalCode: data.postalCode,
           region: data.provinceOrState,
           regionCode: data.provinceOrState,
        };
       
        let addressPayload = data.isShipping && data.isBilling ? {
           ...address,
           addressType: [1, 2, 4]
        } as AddressModel : null;
       
        if (!addressPayload) {
           addressPayload = data.isBilling ? createAddress(address, CustomerAddressType.Billing) : data.isShipping ? createAddress(address, CustomerAddressType.Shipping) : null;
        }
       
        if (!addressPayload) return;
       
        try {
           await saveAddress(addressPayload, true);
           const selectPayload = { value: addressPayload.name, label: `${addressPayload.name}` };
       
           if (addressPayload.addressType?.includes(CustomerAddressType.Billing) && addressPayload.addressType?.includes(CustomerAddressType.Shipping)) {
             const address = addressModelToShippingAndBilling(addressPayload);
             const isComplete = await setDefaultCartAddress(address, address, false);
             if (isComplete) {
               setSelectedBillingAddress(address);
               setBillingSelectValue(selectPayload);
               setSelectedShippingAddress(address);
               setShippingSelectValue(selectPayload);
             }
           } else if (addressPayload.addressType?.includes(CustomerAddressType.Billing)) {
             const billingAddress = addressModelToShippingAndBilling(addressPayload);
             const isComplete = await setDefaultCartAddress(billingAddress, null, shippingSameAsBilling);
             if (isComplete) {
               setSelectedBillingAddress(billingAddress);
               setBillingSelectValue(selectPayload);
             }
           } else if (addressPayload.addressType?.includes(CustomerAddressType.Shipping)) {
             const shippingAddress = addressModelToShippingAndBilling(addressPayload);
             const isComplete = await setDefaultCartAddress(null, shippingAddress, shippingSameAsBilling);
             if (isComplete) {
               setSelectedShippingAddress(shippingAddress);
               setShippingSelectValue(selectPayload);
             }
           }
        } catch (error) {
           console.error(error);
        } finally {
           setAddressSaving(false);
           getAddressBookData();
           setStepOneFormSubmitting(false)
        }
    }

    const handleBillingAddressSelectChange = (selectedOption: SelectOption | null) => {
        if (!selectedOption) {
            setShowBillingForm(true)
        }
    }

    const selectedBillingAddressCallBack = (selectedAddress: AddressModel) => {
        if (selectedAddress) {
            setShowBillingForm(true)
            setSelectedBillingAddress(addressModelToShippingAndBilling(selectedAddress))
        }
    }

    const handleShippingAddressSelectChange = (selectedOption: SelectOption | null) => {
        if (!selectedOption) {
            setShowShippingForm(true)
        }
    }

    const selectedShippingAddressCallBack = (selectedAddress: AddressModel) => {
        if (selectedAddress) {
            setShowShippingForm(true)
            setSelectedShippingAddress(addressModelToShippingAndBilling(selectedAddress))
        }
    }

    const handleAddressSameAsBillingCheckboxChange = () => {
        setShippingSameAsBilling(!shippingSameAsBilling);
    }

    useEffect(()=>{
        if (cartBillingAddress && !selectedBillingAddress)
        {
            setSelectedBillingAddress(cartBillingAddress)
            setBillingSelectValue({value: cartBillingAddress.name ?? "", label: `${cartBillingAddress.name}`})
        }
    },[cartBillingAddress, selectedBillingAddress])

    useEffect(()=>{
        if (cartShippingAddress && !selectedShippingAddress)
        {
            setSelectedShippingAddress(cartShippingAddress)
            setShippingSelectValue({value: cartShippingAddress.name ?? "", label: `${cartShippingAddress.name}`})
        }
    }, [cartShippingAddress, selectedShippingAddress])

    // Hide / Show toggle for the billing and shipping address sections to collapse when one or the other is toggled.
    useEffect(() => {
        if(showBillingForm)
        {
            setShowShippingForm(false)
            if (selectedShippingAddress)
            {
                setSelectedShippingAddress(selectedShippingAddress)
                setShippingSelectValue(selectedShippingAddress ? {value: selectedShippingAddress?.name ?? "", label: `${selectedShippingAddress?.name}`} : null)
            }
            else
            {
                setShippingSelectValue(null)
                setSelectedShippingAddress(null)
            }
            setHasWarning(false)
        }
    }, [showBillingForm])

    useEffect(() => {
        if(showShippingForm)
        {
            setShowBillingForm(false)
            if (selectedBillingAddress)
            {
                setSelectedBillingAddress(selectedBillingAddress)
                setBillingSelectValue(selectedBillingAddress ? {value: selectedBillingAddress?.name ?? "", label: `${selectedBillingAddress?.name}`} : null)
            }
            else
            {
                setBillingSelectValue(null)
                setSelectedBillingAddress(null)
            }
            setHasWarning(false)
        }
    }, [showShippingForm])

    useEffect(()=>{
        if (!showAddressBookModal)
        {
            setSelectBillingAddressCreate(false)
            setSelectShippingAddressCreate(false)
        }
    },[showAddressBookModal])
    
    useEffect(()=>{
        if (!validateAddress(cartBillingAddress?.line1 ?? ""))
        {
          setShippingSameAsBilling(false)
          setDisabled(true)
        }
        if(!validateAddress(cartShippingAddress?.line1 ?? ""))
        {
            setShippingSameAsBilling(false)
            setDisabled(true)
        }
        if(validateAddress(cartShippingAddress?.line1 ?? "") && validateAddress(cartBillingAddress?.line1 ?? ""))
        {
            setDisabled(false)
        }
    
    },[cartBillingAddress, cartShippingAddress])

    const billAddressWarning = validateAddress(cartShippingAddress?.line1 ?? "")? '' : t("Checkout.ShippingSameAsBillingPoBox")
    const filteredBillingAddresses = addresses?.filter(
        x => x.extendedProperties?.find(x=> x?.name === "IsPrimaryD365Address")?.value?.toLowerCase() !== "true" &&
        x.addressType?.includes(CustomerAddressType.Billing)
    )
    const filteredShippingAddresses = addresses?.filter(
        x => x.extendedProperties?.find(x=> x?.name === "IsPrimaryD365Address")?.value?.toLowerCase() !== "true" &&
        x.addressType?.includes(CustomerAddressType.Shipping)
    )
    return (
        <>
            <hr className="text-grey-primary opacity-30 mt-[55px] mb-[37px]"/>

            {/*  Billing section */}
            <section className="CommerceForms EPiServerForms flex flex-col gap-3 p-0">
                <div className='mb-3'>
                    <span className='font-bold text-black text-xl'>{t("Checkout.BillingAddress")}</span>
                </div>
                {!showBillingForm &&
                    <div className="flex flex-col lg:flex-row gap-3">
                        <div className="flex-1">
                            <AddressSelect
                                placeholder={t('AddressBook.SelectBillingPlaceholder')}
                                noResultsText="No billing addresses found"
                                noOptionsText="No billing addresses available"
                                selectValue={billingSelectValue}
                                setSelectValue={setBillingSelectValue}
                                addresses={
                                    filteredBillingAddresses ?? []
                                }
                                onSelectedAddress={selectedBillingAddressCallBack}
                                handleChange={handleBillingAddressSelectChange}
                            />
                        {billAddressWarning && <div className="flex flex-col gap-3">
                            <span className="text-orange-400 flex-[50%]">{billAddressWarning}</span>
                        </div>}
                        </div>
                        <div className="flex-1">
                            <SharedButton onClick={() => {
                                setShowAddressBookModal(true); 
                                setSelectBillingAddressCreate(true) 
                            }} 
                                style="text">
                                <div className="flex flex-row items-center gap-3">
                                    <span>{t('AddressBook.AddNewAddress')}</span>
                                    <PlusIcon className=' fill-black w-[20px] h-[20px]'/>
                                </div>
                            </SharedButton>
                        </div>
                    </div>
                }

                {!showBillingForm && <AddressCard defaultAddress={selectedBillingAddress} />}

                {showBillingForm && <StepOneBillingForm
                    localizations={props.localizations}
                    fullCart={cartData?.fullCart ?? null}
                    defaultCartBillingAddress={selectedBillingAddress}
                    setShowBillingForm={setShowBillingForm}
                    onSubmitForm={onSaveBillingForm}
                />}
                
                {showBillingForm && <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-center mt-8 mb-8'>
                    <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                        <SharedButton
                            size="large"
                            type="button"
                            style="outline" onClick={() => {
                                setShowBillingForm(false)
                                setSelectedBillingAddress(cartBillingAddress)
                                setBillingSelectValue(cartBillingAddress ? {value: cartBillingAddress?.name ?? "", label: `${cartBillingAddress?.name}`} : null)
                            }}>
                            {t("Common.CancelButton")}
                        </SharedButton>
                    </div>
                    <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                        <button className='Button' style={{padding:"14px 12px 14px 12px"}} type="submit" form={'step-one-form-billing'} title="Submit" value="Submission">{!hasWarning ? t("Common.SaveAndContinue") : t("Common.AcceptChanges")}</button>
                    </div>
                </div>}
            </section>

            <hr className="text-grey-primary opacity-30 mt-[37px] mb-[37px]"/>

            {/*  Shipping section */}
            <section className="CommerceForms EPiServerForms flex flex-col gap-3 p-0">
                <div className="flex flex-col CommerceForms EPiServerForms mb-2">
                    <div className="flex flex-col gap-3">
                        <div className={`flex flex-row gap-2`}>
                            <input 
                                className="checkbox" 
                                checked={shippingSameAsBilling}
                                type="checkbox"
                                disabled={disabled}
                                name="shippingSameAsBilling"
                                title="If you want this order to be tax exempt, please check this box." 
                                onChange={handleAddressSameAsBillingCheckboxChange}
                            />
                            <label className={"label flex-1 text-black"} htmlFor="shippingSameAsBilling">{t("Checkout.ShippingSameAsBilling")}</label>
                        </div>
                    </div>
                </div>
                {!shippingSameAsBilling && 
                <>
                    <div className='mb-3'>
                        <span className='font-bold text-black text-xl'>{t("Checkout.ShippingAddress")}</span>
                    </div>
             
                    {!showShippingForm &&
                        <div className="flex flex-col lg:flex-row gap-3">
                            <div className="flex-1">
                            <AddressSelect
                                placeholder={t('AddressBook.SelectShippingPlaceholder')}
                                noResultsText="No shipping addresses found"
                                noOptionsText="No shipping addresses available"
                                selectValue={shippingSelectValue}
                                setSelectValue={setShippingSelectValue}
                                addresses={filteredShippingAddresses ?? []}
                                onSelectedAddress={selectedShippingAddressCallBack}
                                handleChange={handleShippingAddressSelectChange}
                            />
                            </div>
                            <div className="flex-1">
                                <SharedButton 
                                    onClick={() => {
                                        setShowAddressBookModal(true); 
                                        setSelectShippingAddressCreate(true) 
                                    }} 
                                    style="text"
                                >
                                    <div className="flex flex-row items-center gap-3">
                                        <span>{t('AddressBook.AddNewAddress')}</span>
                                        <PlusIcon className=' fill-black w-[20px] h-[20px]'/>
                                    </div>
                                </SharedButton>
                            </div>
                        </div>
                    }

                    {!showShippingForm &&
                        <AddressCard defaultAddress={selectedShippingAddress}            
                    />}

                    {showShippingForm && <StepOneShippingForm
                        localizations={props.localizations}
                        fullCart={cartData?.fullCart ?? null}
                        defaultCartShippingAddress={selectedShippingAddress}
                        setShowShippingForm={setShowShippingForm}
                        onSubmitForm={onSaveShippingForm}
                    />}
                    
                    {showShippingForm && <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-center mt-8 mb-8'>
                        <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                            <SharedButton
                                size="large"
                                type="button"
                                style="outline" 
                                onClick={() => {
                                    setShowShippingForm(false)
                                    setSelectedShippingAddress(cartShippingAddress)
                                    setShippingSelectValue(cartShippingAddress ? { value: cartShippingAddress?.name ?? "", label: `${cartShippingAddress?.name}` } : null)
                                }}
                            >
                                {t("Common.CancelButton")}
                            </SharedButton>
                        </div>
                        <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                            <button className='Button' style={{padding:"14px 12px 14px 12px"}} type="submit" form={'step-one-form-shipping'} title="Submit" value="Submission">{!hasWarning ? t("Common.SaveAndContinue") : t("Common.AcceptChanges") }</button>
                        </div>
                    </div>}
                </>}
            </section>

            <ExtendedModal
                modalTitle={addressFormEditMode ? "Edit your address" : "Add new address"}
                open={showAddressBookModal} 
                onClose={() => {
                    setShowAddressBookModal(false)
                    setAddressFieldsHidden(true)
                }}
            >
                <AddressBookForm
                    selectShipping={selectShippingAddressCreate}
                    selectBilling={selectBillingAddressCreate}
                    localizations={{countries: localizations?.countries ?? [], statesAndProvinces: localizations?.statesAndProvinces ?? []}} 
                    isEditable={addressFormEditMode} 
                    onSubmitForm={async(data, e) => {
                        await onSaveAddress(data)
                        setShowAddressBookModal(false)
                        setAddressFieldsHidden(true)
                    }} 
                    id={`address-book-form`}
                />
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-center mt-8 mb-8'>
                    <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                        <SharedButton
                            size="large"
                            htmlProps={{
                                type: "button"
                            }} 
                            style="outline" 
                            onClick={() => {
                                setShowAddressBookModal(false)
                                setAddressFieldsHidden(true)
                            }}
                        >
                            {t("Common.CancelButton")}
                        </SharedButton>
                    </div>
                    <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
                        <button className={`Button ${addressSaving ? "disabled" : ""}`} disabled={addressSaving} style={{padding:"14px 12px 14px 12px"}} type="submit" form={'address-book-form'} title="Submit" value="Submission">
                            {addressSaving ? 
                                <div className="flex w-full items-center justify-center">
                                  <LoadingSpinner className="h-5 w-5 text-grey-light" />
                                </div>
                                :
                                <>{t('Common.SaveAndContinue')}</>
                            }
                        </button>
                    </div>
                </div>
            </ExtendedModal>
        </>
    )
}

// Set default values to some of the properties.
BillingShippingStep.defaultProps = {}

export default BillingShippingStep
