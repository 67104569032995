import { useGlobalSettings } from '@/stores/global-settings'
import { useMetaDataStore } from '@/stores/metadata-store'
import { ImageProp } from '@/types/shared'
import { Meta, Title } from 'react-head'
import { JsonLd } from 'react-schemaorg'
import { uid } from 'uid'
import { EpiPageContextStateProps } from './types/epi'

const PageHead = (props: EpiPageContextStateProps) => {
  const model = props
  let { language, metadata, url } = useMetaDataStore()
  let { globalSettings } = useGlobalSettings()
  const domain: string = 'https://www.crisisprevention.com'

  if (metadata) {
    metadata = model.metadata
  }

  if (globalSettings) {
    globalSettings = model.globalSettings
  }

  const renderOpenGraphImage = (openGraphImage: ImageProp) => {
    return (
      <>
        {openGraphImage.url && (
          <Meta property="og:image" content={domain + openGraphImage.url} />
        )}
        {openGraphImage.imageType && (
          <Meta property="og:image:type" content={openGraphImage.imageType} />
        )}
        {openGraphImage.imageAlt && (
          <Meta property="og:image:alt" content={openGraphImage.imageAlt} />
        )}
      </>
    )
  }

  const renderRobots = (noIndex?: boolean, noFollow?: boolean) => {
    let content: string[] = []
    noIndex && (content = [...content, 'noindex'])
    noFollow && (content = [...content, 'nofollow'])

    const value = content.join(', ')
    return value && <Meta name="robots" content={value} />
  }

  return (
    <>
      <Meta property="og:type" content="website" />
      <Meta name="twitter:card" content="summary_large_image" />
      {globalSettings?.globalPageTitle && (
        <Meta
          property="og:site_name"
          content={globalSettings.globalPageTitle}
        />
      )}
      {metadata?.seoTitle && (
        <>
          <Title>{metadata.seoTitle}</Title>
          <Meta property="og:title" content={metadata.seoTitle} />
        </>
      )}
      {metadata?.seoDescription && (
        <>
          <Meta name="description" content={metadata.seoDescription} />
          <Meta property="og:description" content={metadata.seoDescription} />
        </>
      )}
      {url && <Meta property="og:url" content={url} />}
      {language?.name && <Meta property="og:local" content={language.name} />}
      {metadata?.openGraphImage &&
        renderOpenGraphImage(metadata.openGraphImage)}
      {!metadata?.openGraphImage &&
        globalSettings?.openGraphFallback &&
        renderOpenGraphImage(globalSettings.openGraphFallback)}
      {metadata?.openGraphMetaData && (
        <>
          {metadata.openGraphMetaData.map((item) => (
            <Meta key={uid()} property={item.name} content={item.content} />
          ))}
        </>
      )}
      {renderRobots(model?.noIndex, model?.noFollow)}
      {metadata?.schemaJSON && (
        <JsonLd item={JSON.parse(metadata?.schemaJSON)} />
      )}
    </>
  )
}

export default PageHead
