import BackToTopButtonBlock from '@/components/blocks/back-to-top-button/back-to-top-button'
import { fullStoryGTM, initializeGTM } from '@/components/shared/analytics/gtm-manager'
import Footer from '@/components/shared/footer/footer'
import { FooterPropsModel, FooterEcommPropsModel } from '@/components/shared/footer/types/footer'
import Header from '@/components/shared/header/header'
import BlockComponentSelector from '@/epi/block-component-selector'
import PageComponentSelector from '@/epi/page-component-selector'
import { EpiPageContextStateProps } from '@/epi/types/epi'
import { useBreadcrumbs } from '@/stores/breadcrumbs'
import { useGlobalSettings } from '@/stores/global-settings'
import { useLocationsStore } from '@/stores/locations-store'
import { useMediaStore } from '@/stores/media-store'
import { useMetaDataStore } from '@/stores/metadata-store'
import { usePageContext } from '@/stores/page-context-store'
import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { useScrollToStore } from '@/stores/rich-text-scroll-to'
import { useSsrInitSettings } from '@/stores/ssr-init-store'
import { useUserStore } from '@/stores/useUserStore'
import { Device, ServerDevice } from '@/types/shared'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'
import { uid } from 'uid'
import HeaderEcomm from '../components/shared/header-ecomm/header-ecomm'
import { useHydrated } from 'react-hydration-provider'
import { ToastContainer } from 'react-toastify'
import FooterEcomm from '../components/shared/footer-ecomm/footer-ecomm'

const Root = ({ initialState, device }: { initialState?: EpiPageContextStateProps; device: ServerDevice }) => {
  const { t, i18n } = useTranslation()
  let clientDevice: Device = 'Unknown'
  switch (device) {
    case 'Desktop':
    case 'Tv':
    case 'Console':
      clientDevice = 'Desktop'
      break
    case 'Tablet':
    case 'Car':
      clientDevice = 'Tablet'
      break
    case 'Mobile':
    case 'Watch':
      clientDevice = 'Mobile'
      break
  }
  const hydrated = useHydrated()
  const [initialized, setInitialized] = useState<boolean>(false)
  const location = useLocation()
  const [data, setData] = useState<EpiPageContextStateProps>(initialState?.pageModel ?? {})
  const [ecommFooter, setEcommFooter] = useState<FooterEcommPropsModel>(initialState?.pageModel?.footer ?? {})
  const [header, setHeader] = useState(initialState?.header ?? {})
  const [footer, setFooter] = useState<FooterPropsModel>(initialState?.footer ?? ({} as FooterPropsModel))
  const [isLoading, setIsLoading] = useState(false)

  const { globalSettings, setGlobalSettings } = useGlobalSettings()
  const { setBreadcrumbItems, setBreadcrumbPageName, setParentLink } = useBreadcrumbs()
  const { setDefaultLanguage, setDefaultSelectedLanguage, setLocations, setSuggestionPopup, selectedCountry } = useLocationsStore()
  const { mobileMenuIsOpen, closeMobileMenu } = usePrimaryMenuMobileStore()
  const { setContentTypes } = usePageContext()
  const { setMetaData, setMetaLanguage, setMetaUrl } = useMetaDataStore()
  const { setSsrInitDevice } = useSsrInitSettings()
  const { user, initializeAuthProvider } = useUserStore(state => state)
  const { handleResize, setIsDesktop, setIsTablet, setIsMobile } = useMediaStore()

  const initializeState = () => {
    if (!initialized) {
      if (clientDevice) {
        setIsDesktop(clientDevice === 'Desktop')
        setIsTablet(clientDevice === 'Tablet')
        setIsMobile(clientDevice === 'Mobile')
      }
      clientDevice && setSsrInitDevice(clientDevice)
      if (initialState) {
        const { breadcrumbs, name, parentLink, header, contentType, metadata, language } = initialState
        const initGlobalSettings = initialState.globalSettings

        initGlobalSettings && setGlobalSettings(initGlobalSettings)

        if (initGlobalSettings) {
          data.globalSettings = initGlobalSettings
        }

        breadcrumbs && setBreadcrumbItems(breadcrumbs)
        name && setBreadcrumbPageName(name)
        parentLink?.url && setParentLink(parentLink.url)

        contentType && setContentTypes(contentType)
        metadata && setMetaData(metadata)

        if (metadata) {
          data.metadata = metadata
        }

        setLocations(header?.locationsMenu?.locations)
        setSuggestionPopup(header?.locationsMenu?.suggestionPopup)
        setDefaultLanguage(language?.name)
        setDefaultSelectedLanguage()
      }
      setInitialized(true)
    }
  }
  initializeState()

  useEffect(() => {
    i18n.changeLanguage(selectedCountry?.location?.cultureCode ?? "en");
  }, [])

  useEffect(() => {
    // skip api call for the first time, since page has been just hydrated
    if (window._skipPageFetch) {
      window._skipPageFetch = false
      return
    }

    if(!location.pathname.toLowerCase().includes("event-reg"))
    {
      fetchPageContext(location.pathname + location.search).catch(console.error)
    }

    if (mobileMenuIsOpen) {
      closeMobileMenu()
    }
  }, [location])

  const fetchPageContext = async (url: string, throwError?: boolean): Promise<Response | void> => {
    setIsLoading(true)

    const headers = { Accept: 'application/json' }

    try {
      const response = await fetch(url, { headers })

      if (!response.ok) {
        if (throwError) {
          throw new Error(response.statusText)
        } else {
          const errorPageUrl = globalSettings?.errorPages?.[response.status.toString()]
          if (errorPageUrl) {
            return await fetchPageContext(errorPageUrl, true)
          }
        }
      }

      const data = await response.json()
      setPageStateFromResponse(data)
    } catch (error) {
      console.log(error)
    }
  }

  const setPageStateFromResponse = function (data: any) {
    if (data.pageModel) {
      setData(data.pageModel)
    } else {
      setData(data)
    }
    setMetaData(data.metadata)
    setMetaLanguage(data.language)
    setMetaUrl(data.url)
    setHeader(data.header)
    setBreadcrumbItems(data.breadcrumbs)
    setBreadcrumbPageName(data.name)
    setParentLink(data.parentLink.url)
    setLocations(data.header.locationsMenu.locations)
    setDefaultLanguage(data.language.name)
    setSuggestionPopup(data.header.locationsMenu.suggestionPopup)
    setFooter(data.footer)
    setGlobalSettings(data.globalSettings)
    setIsLoading(false)
    setEcommFooter(data.pageModel.footer)
  }

  const { initRichTextScrollTo, handleScrollTo } = useScrollToStore()

  const handlePageScrolling = () => {
    if (window.location.hash) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        handleScrollTo(window.location.hash)
      }, 600)
    }
  }

  useEffect(() => {
    handlePageScrolling()
    initRichTextScrollTo()
    handleResize()

    const debouncedHandleResize = () => {
      setTimeout(handleResize, 200)
    }

    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])

  const gtmCode = globalSettings?.googleTMCode
  useEffect(() => {
    if (typeof gtmCode !== 'undefined') {
      initializeGTM(gtmCode)
    }
  }, [gtmCode])

  const [triggerFullStoryGTM, setTriggerFullStoryGTM] = useState<boolean>(false)
  const cultureCode = selectedCountry?.location?.cultureCode

  useEffect(() => {
    initializeAuthProvider()
  }, [])

  useEffect(() => {
    if (triggerFullStoryGTM) return
    if (!user) return
    fullStoryGTM(user, cultureCode)
    setTriggerFullStoryGTM(true)
  }, [cultureCode])

  return (
    <>
      {!isLoading && (
        <div className="overflow-x-clip">
          <div id="sticky-header" className="sticky top-0 z-[999]">
            {!data.contentType?.includes('CommerceBasePage') && !data.contentType?.includes('WorkplaceTrainingLandingPage') && <Header model={header}></Header>}
            {data.contentType?.includes('CommerceBasePage') && <HeaderEcomm></HeaderEcomm>}
            {globalSettings?.siteAlert && (
              <>{globalSettings?.siteAlert.map((alert) => <BlockComponentSelector key={uid()} {...alert} />)}</>
            )}
          </div>
          <main id="mainContent" className="flex flex-col" aria-label={hydrated ? t('ScreenReader.MainPageContent') : undefined}>
            <Routes>
              <Route path="*" element={<PageComponentSelector model={data} />} />
            </Routes>
          </main>

          {!data.contentType?.includes('CommerceBasePage') && !data.contentType?.includes('WorkplaceTrainingLandingPage') && <Footer model={footer}></Footer>}
          {data.contentType?.includes('CommerceBasePage') && <FooterEcomm model={ecommFooter}></FooterEcomm>}
          <BackToTopButtonBlock />
          {hydrated && <ToastContainer/>}
        </div>
      )}
    </>
  )
}
export default Root
